export enum RecipesFiltersEnum {
  brand = 'brand',
  producerColorCode = 'producerColorCode',
  producerColor = 'producerColor',
  model = 'model',
  colorSystem = 'colorSystem',
  colorBook = 'colorBook',
  flashcard = 'flashcard',
  measurementId = 'measurementId',
  effectTypes = 'effectTypes',
  grainSize = 'grainSize',
}
